import { useEffect, useMemo, useState } from 'react';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { CarePlanCycleType, useGetCareEnrollmentInfoQuery } from 'shared/types/graphql';
import { useManageMembershipContext } from '../components/ManageMembership/context/ManageMembershipContext';
import { PaymentsMade } from '../components/ManageMembership/types';
import {
  calcAccountCredit,
  calcDiscount,
  calcExams,
  calcFees,
  calcPaymentsMade,
  calcRemainingMembershipBalance,
  calcSubtotal,
  calcTeletriage,
  calcTotalSavings,
  ItemInfo,
  calcAndSetBalanceDue,
} from '../utils/membershipCalculator';
import { UsePetBenefitCareBenefit } from './usePetBenefits';

interface UseCancellationCalculatorInput {
  enrollmentId: string;
  benefitUsages: UsePetBenefitCareBenefit[];
}

interface UseCancellationCalculatorOutput {
  hasPlanDiscount: boolean;
  accountCredit: ItemInfo;
  exams: ItemInfo;
  discount: ItemInfo;
  teletriage: ItemInfo;
  fees: ItemInfo;
  subtotal: ItemInfo;
  paymentsMade: PaymentsMade;
  balanceDue: number;
  balanceDueWithoutManualAdjustment: number;
  totalSavings: number;
  remainingMembershipBalance: PaymentsMade;
  isLoading: boolean;
}

export const useCancellationCalculator = ({
  enrollmentId,
  benefitUsages,
}: UseCancellationCalculatorInput): UseCancellationCalculatorOutput => {
  const { setBalanceDue, applyDiscounts, includeFees, includeManualAdjustment, manualAdjustment } =
    useManageMembershipContext();
  const [balanceDueWithoutManualAdjustment, setBalanceDueWithoutManualAdjustment] = useState(0);
  const [internalBalanceDue, setInternalBalanceDue] = useState(0);
  const manualAdjustmentValue = useMemo(() => Number(manualAdjustment), [manualAdjustment]);
  const { data, loading: isLoading } = useGetCareEnrollmentInfoQuery({
    variables: {
      enrollmentId,
    },
    fetchPolicy: GraphQLFetchPolicies.NetworkOnly,
  });

  const cancellationCalcInfo = data?.findUniqueCarePlanEnrollment;
  const plan = cancellationCalcInfo?.plan;
  const planPricing = useMemo(
    () =>
      cancellationCalcInfo?.planPricing ??
      plan?.planPricings.find((pricing) => pricing.billingCycle === CarePlanCycleType.Monthly),
    [cancellationCalcInfo?.planPricing, plan?.planPricings],
  );
  const hasPlanDiscount = !!planPricing?.planDiscount;
  const planDiscount = planPricing?.planDiscount ? planPricing?.planDiscount / 100 : 0;

  const accountCredit: ItemInfo = useMemo(() => {
    return calcAccountCredit({ benefitUsages, planDiscount });
  }, [benefitUsages, planDiscount]);

  const exams: ItemInfo = useMemo(() => {
    return calcExams({ benefitUsages, planDiscount });
  }, [benefitUsages, planDiscount]);

  const discount: ItemInfo = useMemo(() => {
    return calcDiscount({ benefitUsages });
  }, [benefitUsages]);

  const teletriage: ItemInfo = useMemo(() => {
    return calcTeletriage({
      startDate: cancellationCalcInfo?.startDate,
      planBenefits: plan?.planBenefits,
    });
  }, [cancellationCalcInfo?.startDate, plan?.planBenefits]);

  const paymentsMade: PaymentsMade = useMemo(() => {
    return calcPaymentsMade({
      payments: cancellationCalcInfo?.enrollmentPayments,
      pricePerRenewal: planPricing?.pricePerRenewal,
      renewalCycle: planPricing?.renewalCycle,
    });
  }, [cancellationCalcInfo?.enrollmentPayments, planPricing?.pricePerRenewal, planPricing?.renewalCycle]);

  const subtotal: ItemInfo = useMemo(() => {
    return calcSubtotal({ accountCredit, discount, exams, paymentsMade, teletriage });
  }, [accountCredit, discount, exams, paymentsMade, teletriage]);

  const fees: ItemInfo = useMemo(() => {
    return calcFees({
      includeFees,
      paymentsMadeTotal: paymentsMade.total,
    });
  }, [includeFees, paymentsMade.total]);

  const totalSavings: number = useMemo(() => {
    return calcTotalSavings({
      accountCreditSavings: accountCredit.savings,
      discountUsedValue: discount.usedValue,
      examsSavings: exams.savings,
    });
  }, [accountCredit.savings, discount.usedValue, exams.savings]);

  useEffect(() => {
    const { amountToUse, amountToUseWithoutManualAdjustment } = calcAndSetBalanceDue({
      subtotal,
      discount,
      totalSavings,
      planDiscount: hasPlanDiscount,
      includeFees,
      fees,
      includeManualAdjustment,
      manualAdjustmentValue,
      applyDiscounts,
    });
    setBalanceDue(amountToUse);
    setInternalBalanceDue(amountToUse);
    setBalanceDueWithoutManualAdjustment(amountToUseWithoutManualAdjustment);
  }, [
    applyDiscounts,
    discount,
    fees,
    hasPlanDiscount,
    includeFees,
    includeManualAdjustment,
    manualAdjustment,
    manualAdjustmentValue,
    planDiscount,
    setBalanceDue,
    subtotal,
    totalSavings,
  ]);

  const remainingMembershipBalance: PaymentsMade = useMemo(() => {
    return calcRemainingMembershipBalance({
      paymentsMadeTotal: paymentsMade.total,
      pricePerRenewal: planPricing?.pricePerRenewal,
      renewalCycle: planPricing?.renewalCycle,
    });
  }, [paymentsMade.total, planPricing?.pricePerRenewal, planPricing?.renewalCycle]);

  return {
    hasPlanDiscount,
    accountCredit,
    exams,
    discount,
    teletriage,
    fees,
    subtotal,
    paymentsMade,
    balanceDue: internalBalanceDue,
    balanceDueWithoutManualAdjustment,
    totalSavings,
    remainingMembershipBalance,
    isLoading,
  };
};
