import { Box, Divider, TabPanel, TabProps, TabsLayout } from '@televet/kibble-ui';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from '@televet/kibble-ui/build/components/Modal';
import { useModal } from '@televet/televet-ui';
import { formatDuration, intervalToDuration } from 'date-fns';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { default as React, useEffect, useMemo } from 'react';
import LoadingIndicator from 'shared/components/LoadingIndicator';
import { GA4Events } from 'shared/enums/GA4Events';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { ModalNames } from 'shared/enums/ModalNames';
import useGA from 'shared/hooks/useGA';
import useNavigateToFormSubmission from 'shared/hooks/useNavigateToFormSubmission';
import { getPersistedAppointmentFilters } from 'shared/providers/ApolloProvider/reactiveVars/appointmentFilters';
import {
  ChatEntityAppointmentFragment,
  ChatEntityFormSubmissionFragment,
  useGetAppointmentFiltersQuery,
  useGetChatEntitiesQuery,
} from 'shared/types/graphql';
import { ChatEntityType } from '../enums/ChatEntityType';
import { AppointmentData } from '../types/AppointmentData';
import { AttachmentFormData } from '../types/AttachmentFormData';
import { AppointmentList } from './AppointmentList';
import { FormList } from './FormList';

type ConversationDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  clinicPetParentIds: string[];
};

const formatAppointmentDuration = (durationInMinutes: number): string => {
  if (durationInMinutes < 60) {
    // If the duration is less than 60 minutes, display in minutes
    return `${durationInMinutes} minutes`;
  } else {
    // If the duration is 60 minutes or more, convert it to hours and minutes
    const duration = intervalToDuration({
      start: 0,
      end: durationInMinutes * 60 * 1000, // Convert minutes to milliseconds
    });

    return formatDuration({
      hours: duration.hours,
      minutes: duration.minutes,
    });
  }
};

const ConversationDetailsModal = ({
  onClose,
  isOpen,
  clinicPetParentIds,
}: ConversationDetailsModalProps): JSX.Element => {
  const {
    data: chatEntitiesData,
    previousData: previousChatEntitiesData,
    refetch,
  } = useGetChatEntitiesQuery({
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    variables: {
      appointmentWhereInput: { clinicPetParents: { some: { id: { in: clinicPetParentIds } } } },
      formSubmissionsWhereInput: { clinicPetParent: { id: { in: clinicPetParentIds } } },
    },
  });
  const { openModal } = useModal();
  const { navigateToFormSubmission } = useNavigateToFormSubmission();
  const { data: appointmentFiltersData } = useGetAppointmentFiltersQuery();
  const { gaTrack } = useGA();

  // Make sure to refetch the data when the modal is opened
  useEffect(() => {
    if (isOpen) {
      gaTrack(GA4Events.CONVERSATION_MODAL_OPEN);
      refetch();
    }
  }, [gaTrack, isOpen, refetch]);

  const defaultAppointmentDurationInMinutes = useMemo(() => {
    const appointmentFilters = appointmentFiltersData?.appointmentFilters || getPersistedAppointmentFilters();
    return appointmentFilters.defaultAppointmentDurationInMinutes;
  }, [appointmentFiltersData]);

  const handleChatEntitySelect = (id: string, type: ChatEntityType): void => {
    if (type === ChatEntityType.Appointment) {
      const appointment = chatEntitiesData?.appointments?.find((a: ChatEntityAppointmentFragment) => {
        return a.id === id;
      });
      if (appointment) {
        openModal(ModalNames.Appointment, {
          startDate: parseISO(appointment.startAt),
          durationInMinutes: appointment.durationInMinutes,
          defaultAppointmentDurationInMinutes,
          appointmentId: id,
        });
      }
    } else {
      const formSubmission = chatEntitiesData?.formSubmissions?.find(
        (f: ChatEntityFormSubmissionFragment) => f.id === id,
      );
      if (formSubmission?.clinicPetParentId) {
        navigateToFormSubmission({
          formSubmissionId: formSubmission.id,
          clinicPetParentId: formSubmission.clinicPetParentId,
        });
      }
    }

    onClose();
  };

  const memoizedData = useMemo(() => {
    if (!chatEntitiesData) return { upcomingAppointments: [], pastAppointments: [], formSubmissions: [] };

    const isAppointmentUpcoming = (appointment: ChatEntityAppointmentFragment): boolean => {
      const appointmentDate = parseISO(appointment.startAt);
      const currentDate = new Date();
      return appointmentDate > currentDate;
    };

    const upcomingAppointments: AppointmentData[] = [];
    const pastAppointments: AppointmentData[] = [];
    const formSubmissions: AttachmentFormData[] = [];

    chatEntitiesData.appointments?.forEach((appointment: ChatEntityAppointmentFragment) => {
      const appointmentCard: AppointmentData = {
        id: appointment.id,
        title: appointment.appointmentType?.name || 'Appointment',
        startsAtStr: `${format(parseISO(appointment.startAt), 'MM/dd/yy')} at
        ${format(parseISO(appointment.startAt), 'h:mm aa')}`,
        doctor: `${appointment.clinicEmployees?.[0]?.firstName} ${appointment.clinicEmployees?.[0]?.lastName}` || '',
        duration: formatAppointmentDuration(appointment.durationInMinutes),
      };

      if (isAppointmentUpcoming(appointment)) {
        upcomingAppointments.push(appointmentCard);
      } else {
        pastAppointments.push(appointmentCard);
      }
    });

    chatEntitiesData.formSubmissions?.forEach((formSubmission: ChatEntityFormSubmissionFragment) => {
      const formData: AttachmentFormData = {
        id: formSubmission.id,
        title: formSubmission.formTemplate?.title || 'Unknown',
        createdAt: `${format(parseISO(formSubmission.createdAt), 'MM/dd/yy')} at
        ${format(parseISO(formSubmission.createdAt), 'h:mm aa')}`,
        isCompleted: !!formSubmission.submittedAt,
      };

      formSubmissions.push(formData);
    });

    return { upcomingAppointments, pastAppointments, formSubmissions };
  }, [chatEntitiesData]);

  const { upcomingAppointments, pastAppointments, formSubmissions } = memoizedData;

  const tabList = useMemo(() => {
    const tabs: TabProps[] = [
      {
        label: 'Appointments',
        color: 'text.default',
        flex: 1,
        onClick: (): void => {
          gaTrack(GA4Events.CONVERSATION_MODAL_TAB_APPT_CLICK);
        },
      },
      {
        label: 'Forms',
        color: 'text.default',
        flex: 1,
        onClick: (): void => {
          gaTrack(GA4Events.CONVERSATION_MODAL_TAB_FORM_CLICK);
        },
      },
    ];
    return tabs;
  }, [gaTrack]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="md" isCentered scrollBehavior="inside" allowEscape={true}>
      <ModalHeader pb={1}>
        <Heading size="sm">Client History</Heading>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody pt={0} px={0}>
        <Box height={400}>
          {!chatEntitiesData && !previousChatEntitiesData ? (
            <Box p={30}>
              <LoadingIndicator />
            </Box>
          ) : (
            <TabsLayout
              tabList={tabList}
              tabListProps={{
                position: 'sticky',
                top: 0,
                bg: 'white',
                zIndex: 1,
              }}
              tabPanels={[
                <TabPanel key="appointment-tab" pt={5} px={4}>
                  <AppointmentList
                    title="Upcoming"
                    appointments={upcomingAppointments}
                    handleChatEntitySelect={handleChatEntitySelect}
                  />
                  <Divider borderBottomWidth="1px" borderBottomColor="border.default" mt={3} mb={5} />
                  <AppointmentList
                    title="Past"
                    appointments={pastAppointments}
                    handleChatEntitySelect={handleChatEntitySelect}
                  />
                </TabPanel>,
                <TabPanel key="form-tab" pt={4} px={4}>
                  <FormList forms={formSubmissions} handleChatEntitySelect={handleChatEntitySelect} />
                </TabPanel>,
              ]}
            />
          )}
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default ConversationDetailsModal;
