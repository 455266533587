import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Flex, Button, Box, Text, useMediaQuery, VStack } from '@televet/kibble-ui';
import { useInView } from 'react-intersection-observer';
import omit from 'lodash-es/omit';
import omitBy from 'lodash-es/omitBy';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import orderBy from 'lodash-es/orderBy';
import uniqBy from 'lodash-es/uniqBy';
import { Mixpanel } from 'shared/utils/mixpanel';
import { RouteDefinitions, replaceParam, RouteBasePaths } from 'routes';
import {
  ChannelStatusAction,
  ChannelType as ChannelType,
  FilterSelectionType,
  SearchChannelInput,
  ChannelListChannelFragment,
  SortOrder,
  useGetAllClinicChannelStatusesQuery,
  useGetChannelListChannelsQuery,
  useSubscribeToChannelListChannelsSubscription,
} from 'shared/types/graphql';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import { PersistedStateKeys } from 'shared/enums/PersistedStateKeys';
import useClinicUser from 'shared/hooks/useClinicUser';
import usePersistedState from 'shared/hooks/usePersistedState';
import usePrevious from 'shared/hooks/usePrevious';
import ChannelListToolbar, { ChannelListFilterType } from './ChannelListToolbar';
import ChannelListItem from './ChannelListItem';
import { IChannelListFilter, defaultChannelListFilter } from '../../interfaces/IChannelListFilter';
import { DeviceSizes } from 'shared/enums/DeviceSizes';
import useUnreadMessageCount from 'shared/hooks/useUnreadMessageCount';
import { ReactComponent as EmptyStateIllustration } from 'pages/Conversations/assets/channel-list-empty-state.svg';
import { ChannelSearchInput, ChannelSearchInputUpdates } from '../../interfaces/ChannelSearchInput';
import LoadingIndicator from 'shared/components/LoadingIndicator';
import {
  defaultChannelListFilterSelection,
  IChannelListFilterSelection,
} from '../../interfaces/IChannelListFilterSelection';
import { Mode } from 'shared/components/SidePanel/components/SearchPanel/shared/types/enum/Mode';
import { useSidePanelSearch } from 'shared/components/SidePanel/components/SearchPanel/state/providers/SearchPanelProvider';
import { QuickSelectOptionValues } from './ChannelListFilter/hooks/useChannelFilterOptions';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';

export const DEFAULT_CHANNEL_LIST_PAGE_SIZE = 20;
export const DEFAULT_PINNED_CHANNEL_LIST_PAGE_SIZE = 100;

const ChannelList = (): JSX.Element => {
  const { channelId } = useParams<{ channelId: string }>();
  const [isSearching, setIsSearching] = useState(false);
  const [channelSearchInput, setChannelSearchInput] = useState<ChannelSearchInput>({});
  const [hasLoadedInitialChannelList, setHasLoadedInitialChannelList] = useState(false);
  const { setSearchPanelMode } = useSidePanelSearch();
  const { isFeatureEnabled } = useFeatureFlag();
  const [isTablet, isDesktop] = useMediaQuery([`(${DeviceSizes.TabletMinWidth})`, `(${DeviceSizes.DesktopMinWidth})`]);
  const history = useHistory();
  const { clinicUser, clinicUserId, currentClinicId } = useClinicUser();
  const userId = clinicUser?.id || '';
  const clinicKey = `Clinic:${currentClinicId}`;
  const userKey = `User:${userId}`;

  const isSortConversationsByLastMessageEnabled = isFeatureEnabled(FeatureFlagName.SortConversationByLastMessage);

  const [channelListFilters, setChannelListFilters] = usePersistedState<
    Record<string, Record<string, IChannelListFilter>> | undefined | null
  >(PersistedStateKeys.ChannelListFilters, { [clinicKey]: { [userKey]: defaultChannelListFilter } });

  const [channelListFilterSelections, setChannelListFilterSelections] = usePersistedState<
    Record<string, Record<string, IChannelListFilterSelection>> | undefined | null
  >(PersistedStateKeys.ChannelListFilterSelections, {
    [clinicKey]: { [userKey]: defaultChannelListFilterSelection },
  });

  // Old conversation filters persisted only to local storage
  const oldChannelListFilter = useMemo(() => {
    if (
      !currentClinicId ||
      !userId ||
      !channelListFilters ||
      !channelListFilters[clinicKey] ||
      !channelListFilters[clinicKey][userKey]
    ) {
      return defaultChannelListFilter;
    }
    return channelListFilters[clinicKey][userKey];
  }, [channelListFilters, currentClinicId, clinicKey, userId, userKey]);

  // New conversation filters persisted to the database
  const currentChannelFilterSelection = useMemo(() => {
    if (
      !currentClinicId ||
      !userId ||
      !channelListFilterSelections ||
      !channelListFilterSelections[clinicKey] ||
      !channelListFilterSelections[clinicKey][userKey]
    ) {
      return defaultChannelListFilterSelection;
    }
    return channelListFilterSelections[clinicKey][userKey];
  }, [channelListFilterSelections, currentClinicId, clinicKey, userId, userKey]);

  const channelListFilter = useMemo(() => {
    if (currentChannelFilterSelection) {
      return currentChannelFilterSelection.filters;
    }
    if (oldChannelListFilter) {
      return oldChannelListFilter;
    }
    return defaultChannelListFilter;
  }, [oldChannelListFilter, currentChannelFilterSelection]);

  const {
    data: { unreadChannelMap: unreadMessageCountByChannel },
  } = useUnreadMessageCount();

  const unreadChannelIds = useMemo(() => {
    const ids = Object.entries(unreadMessageCountByChannel)
      .filter(([, { count }]) => count > 0)
      .map(([id]) => id);
    // We always want to display the current channel
    if (channelId) {
      ids.push(channelId);
    }
    return ids;
  }, [channelId, unreadMessageCountByChannel]);

  const showUnreadEmptyState = useMemo(() => {
    return Boolean(channelListFilter.unread && unreadChannelIds?.length === 0);
  }, [channelListFilter, unreadChannelIds]);

  const { data: channelStatusesData } = useGetAllClinicChannelStatusesQuery({
    variables: { clinicId: currentClinicId || '' },
    fetchPolicy: GraphQLFetchPolicies.CacheFirst,
    skip: !currentClinicId,
  });

  const channelListQueryWhere: SearchChannelInput = useMemo(() => {
    const includeUnassigned =
      channelListFilter?.assigneeIds.filter((option) => option === QuickSelectOptionValues.UNASSIGNED).length > 0;
    const includeAssignedToMe =
      channelListFilter?.assigneeIds.filter((option) => option === QuickSelectOptionValues.ASSIGNED_TO_ME).length > 0;
    const assignees = [...channelListFilter.assigneeIds];

    if (includeAssignedToMe && clinicUser?.id) {
      assignees.push(clinicUser?.id);
    }

    let channelStatusIds = channelListFilter.channelStatusIds.slice();

    if (!channelListFilter.showArchived && !channelListFilter.channelStatusIds.length) {
      channelStatusIds = (channelStatusesData?.findManyChannelStatus || [])
        .filter((channelStatus) => channelStatus.channelStatusAction === ChannelStatusAction.Active)
        .map(({ id }) => id);
    }

    return {
      ...omit(channelListFilter, 'unread', 'assigneeIds', 'showArchived'),
      idIn: channelListFilter.unread ? unreadChannelIds : [],
      ...channelSearchInput,
      channelType: ChannelType.Client,
      clinicId: currentClinicId,
      isActive: true,
      assigneeIds: assignees,
      channelStatusIds,
      isUnassigned: includeUnassigned,
    };
  }, [currentClinicId, channelListFilter, channelSearchInput, unreadChannelIds, clinicUser, channelStatusesData]);

  /**
   * If archived conversations are hidden and no specific statuses are already included in the filter, wait
   * until channel statuses are returned before fetching channels because we need the active status IDs
   * to only fetch active channels.
   */
  const shouldFetchChannels = useMemo(
    () =>
      channelListFilter.showArchived ||
      (!channelListFilter.showArchived && (!!channelListFilter.channelStatusIds.length || !!channelStatusesData)),
    [channelListFilter, channelStatusesData],
  );

  const {
    data: channelsData,
    loading: isLoadingChannelList,
    variables: channelListVariables,
    refetch: refetchChannels,
  } = useGetChannelListChannelsQuery({
    fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    skip: !currentClinicId || !shouldFetchChannels,
    variables: {
      where: { ...channelListQueryWhere, excludedChannelPinUserIds: [clinicUserId || ''] },
      take: DEFAULT_CHANNEL_LIST_PAGE_SIZE,
      skip: 0,
    },
    onCompleted: () => {
      if (!hasLoadedInitialChannelList) {
        setHasLoadedInitialChannelList(true);
      }
    },
  });

  const {
    data: pinnedChannelsData,
    refetch: refetchPinnedChannels,
    loading: isLoadingPinnedChannelList,
  } = useGetChannelListChannelsQuery({
    skip: !currentClinicId || !shouldFetchChannels,
    variables: {
      where: {
        channelPinUserIds: [clinicUserId || ''],
        clinicId: currentClinicId,
      },
      take: DEFAULT_PINNED_CHANNEL_LIST_PAGE_SIZE,
      skip: 0,
    },
  });

  const unpinnedChannels = useMemo(() => channelsData?.channelSearch?.channels || [], [channelsData]);
  const pinnedChannels = useMemo(() => pinnedChannelsData?.channelSearch?.channels || [], [pinnedChannelsData]);
  const [sortedChannels, setSortedChannels] = useState<ChannelListChannelFragment[]>([]);

  useEffect((): void => {
    if (!isLoadingChannelList && !isLoadingPinnedChannelList) {
      const allChannels = [...pinnedChannels, ...unpinnedChannels].map((channel) => ({
        ...channel,
        isPinned: !!channel.channelPins?.find((u) => u.userId === clinicUserId),
      }));

      // Pinned channels come first
      // Pinned channels are then sorted by createdAt date
      // Unpinned channels are sorted by createdAt if the sort by last message FF is enabled
      // Otherwise they are sorted by updatedAt
      const sortBy = [
        (channel: ChannelListChannelFragment): boolean => !channel.isPinned,
        (channel: ChannelListChannelFragment): Date =>
          (channel.channelPins || []).find((cp) => cp.userId === clinicUserId)?.createdAt,
        (channel: ChannelListChannelFragment): Date =>
          isSortConversationsByLastMessageEnabled
            ? channel.lastMessage?.createdAt || channel.updatedAt
            : channel.updatedAt,
      ];

      // Asc to sort pinned channels first
      // Desc to sort pinned channels by descending date
      // Desc to sort all other channels by descending date
      const sortOrders = [SortOrder.Asc, SortOrder.Asc, SortOrder.Desc];

      const _sortedChannels = orderBy(allChannels, sortBy, sortOrders);

      setSortedChannels(uniqBy(_sortedChannels, 'id'));
    }
  }, [
    pinnedChannels,
    unpinnedChannels,
    clinicUserId,
    isSortConversationsByLastMessageEnabled,
    isLoadingChannelList,
    isLoadingPinnedChannelList,
  ]);

  const hasNextPage = useMemo(() => {
    if (showUnreadEmptyState) {
      return false;
    }
    if (!channelsData?.channelSearch.channels) {
      return true;
    }
    const { total, channels } = channelsData.channelSearch;
    return total > channels.length;
  }, [channelsData, showUnreadEmptyState]);

  const handleChannelSelect = useCallback(
    (channelId: string) => {
      setSearchPanelMode(Mode.Default);
      history.push(`${replaceParam(RouteDefinitions.Conversations, ':channelId', channelId)}`);
    },
    [setSearchPanelMode, history],
  );

  useSubscribeToChannelListChannelsSubscription({
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId,
    onData: async () => {
      let unpinnedChannelsTake = DEFAULT_CHANNEL_LIST_PAGE_SIZE;
      if (channelListVariables) {
        unpinnedChannelsTake = channelListVariables.skip + channelListVariables.take;
      }
      // Race condition where the pinned channels are not yet loaded
      setTimeout(async () => {
        await refetchPinnedChannels({ skip: 0, take: DEFAULT_PINNED_CHANNEL_LIST_PAGE_SIZE });
        await refetchChannels({ skip: 0, take: unpinnedChannelsTake });
      }, 0);
    },
  });
  const isTeletriageSupportUser = clinicUser?.email === 'triage@chronosllc.com';
  /**
   * Refresh channel list every 3 minutes at a minimum
   */
  useEffect(() => {
    const THREE_MINUTES_IN_MS = 1000 * 60 * 3;
    const THIRTY_SECONDS_IN_MS = 1000 * 30;

    const interval_in_ms = isTeletriageSupportUser ? THIRTY_SECONDS_IN_MS : THREE_MINUTES_IN_MS;
    const interval = setInterval(() => {
      if (refetchChannels) refetchChannels();
    }, interval_in_ms);
    return (): void => clearInterval(interval);
  }, [refetchChannels, isTeletriageSupportUser]);

  const channelListContainerRef = useRef<HTMLDivElement | null>(null);

  /**
   * When a filter changes, reset to the first page and scroll to the top
   */
  useEffect(() => {
    if (channelListContainerRef.current) {
      channelListContainerRef.current.scrollTop = 0;
    }
  }, [channelSearchInput, channelListFilter, channelListContainerRef]);

  const handleSearchToggle = useCallback((isSearching) => {
    setChannelSearchInput({});
    setIsSearching(isSearching);
    if (isSearching) {
      Mixpanel.track('Viewed conversations list search panel', { isEnhancedConversationsSearch: true });
    }
  }, []);

  const handleFilterSelect = useCallback(
    (filterSelection: Partial<IChannelListFilterSelection>, ignoreFilterKeys?: (keyof IChannelListFilter)[]) => {
      if (!currentClinicId || !userId) return;

      let showArchived = !!filterSelection.filters?.showArchived;
      if (!showArchived && filterSelection.filters?.channelStatusIds) {
        const hasArchivedStatus = filterSelection.filters.channelStatusIds.some((id) => {
          const channelStatus = channelStatusesData?.findManyChannelStatus?.find((status) => status.id === id);
          return channelStatus && channelStatus.channelStatusAction !== ChannelStatusAction.Active;
        });
        if (hasArchivedStatus) {
          showArchived = true;
        }
      }

      setChannelListFilterSelections((value) => {
        return {
          ...value,
          [clinicKey]: {
            ...value?.[clinicKey],
            [userKey]: {
              ...value?.[clinicKey]?.[userKey],
              ...filterSelection,
              filters: {
                ...value?.[clinicKey]?.[userKey]?.filters,
                showArchived, // Ensure we hide archived conversations by default
                ...omit(filterSelection.filters, ignoreFilterKeys || []),
              },
            },
          },
        };
      });
    },
    [currentClinicId, clinicKey, userId, userKey, channelStatusesData, setChannelListFilterSelections],
  );

  const handleShowArchivedToggle = useCallback(() => {
    const filters = currentChannelFilterSelection.filters;
    const showArchived = !filters.showArchived;
    let channelStatusIds = filters.channelStatusIds.slice();

    if (!showArchived && filters.channelStatusIds.length) {
      const archivedStatuses = new Set(
        filters.channelStatusIds.filter((id) => {
          const channelStatus = channelStatusesData?.findManyChannelStatus?.find((status) => status.id === id);
          return channelStatus && channelStatus.channelStatusAction !== ChannelStatusAction.Active;
        }),
      );
      if (archivedStatuses.size) {
        channelStatusIds = channelStatusIds.filter((id) => !archivedStatuses.has(id));
      }
    }

    handleFilterSelect({
      ...defaultChannelListFilterSelection,
      filters: {
        ...currentChannelFilterSelection.filters,
        showArchived,
        channelStatusIds,
      },
    });
  }, [channelStatusesData?.findManyChannelStatus, currentChannelFilterSelection, handleFilterSelect]);

  /**
   * Immediately apply and clear any old filters for this clinic user
   */
  useEffect(() => {
    if (JSON.stringify(oldChannelListFilter) !== JSON.stringify(defaultChannelListFilter)) {
      handleFilterSelect({
        id: '',
        name: '',
        isDeleted: false,
        filterSelectionType: FilterSelectionType.Private,
        filters: oldChannelListFilter,
      });
      setChannelListFilters((value) => omit(value, `${clinicKey}.${userKey}`));
    }
  }, [clinicKey, userKey, oldChannelListFilter, handleFilterSelect, setChannelListFilters]);

  const clearChannelFilter = useCallback(
    (source?: string, ignoreFilterKeys?: (keyof IChannelListFilter)[]): void => {
      handleFilterSelect(defaultChannelListFilterSelection, [...(ignoreFilterKeys || []), 'showArchived']);
      if (source) {
        Mixpanel.track('Conversation list filter cleared', { source });
      }
    },
    [handleFilterSelect],
  );

  const handleChannelListViewSelect = useCallback(
    (filterType: ChannelListFilterType): void => {
      handleFilterSelect({
        ...currentChannelFilterSelection,
        filters: {
          ...currentChannelFilterSelection.filters,
          unread: filterType === ChannelListFilterType.Unread,
        },
      });
      if (filterType === ChannelListFilterType.Unread) {
        history.push(RouteBasePaths.Conversations);
      }
    },
    [currentChannelFilterSelection, history, handleFilterSelect],
  );

  const isFilterApplied = useMemo(() => !isEqual(channelListFilter, defaultChannelListFilter), [channelListFilter]);

  const handleChannelSearchInputChange = useCallback(
    (updates: ChannelSearchInputUpdates): void => {
      const newChannelSearchInput = omitBy(updates, isEmpty);
      setChannelSearchInput(newChannelSearchInput);
      const inputCount = Object.values(newChannelSearchInput).filter((v) => !!v).length;
      Mixpanel.track('Searched conversations list', {
        isFilterApplied,
        showArchived: channelListFilter.showArchived,
        inputCount,
      });
    },
    [channelListFilter, isFilterApplied],
  );

  const { inView, ref: channelListEndRef } = useInView({
    root: channelListContainerRef?.current,
    skip: !hasLoadedInitialChannelList,
    rootMargin: '0px 0px 250px 0px',
  });
  const wasInView = usePrevious(inView);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);

  const getNextPage = useCallback(() => {
    if (hasNextPage) {
      refetchChannels({ take: DEFAULT_CHANNEL_LIST_PAGE_SIZE, skip: unpinnedChannels.length });
    }
  }, [unpinnedChannels.length, hasNextPage, refetchChannels]);

  /**
   * Load the next page of channels when the user scrolls to the bottom
   */
  useEffect(() => {
    if (!wasInView && inView && !isLoadingChannelList) {
      setIsLoadingNextPage(true);
      getNextPage();
    }
    if (wasInView && !inView) {
      setIsLoadingNextPage(false);
    }
  }, [inView, wasInView, isLoadingChannelList, getNextPage]);

  const searchResultsCount = useMemo(() => {
    if (channelListFilter.unread && !unreadChannelIds?.length) {
      return 0;
    }
    return channelsData?.channelSearch.total || 0;
  }, [channelsData, channelListFilter, unreadChannelIds]);

  const showSearchResultsCount = useMemo(() => {
    const { petParentNames, petNames, messageBodies } = channelSearchInput;
    return !isLoadingChannelList && (!!petParentNames || !!petNames || !!messageBodies);
  }, [isLoadingChannelList, channelSearchInput]);

  const showEmptyState = useMemo(() => {
    if (isLoadingChannelList || !channelsData?.channelSearch) {
      return false;
    }
    const {
      channelSearch: { total, channels },
    } = channelsData;
    return !isLoadingChannelList && ((showSearchResultsCount && total === 0) || (channels && channels?.length === 0));
  }, [isLoadingChannelList, showSearchResultsCount, channelsData]);

  const conversationsEmptyState = useMemo(() => {
    return (
      <VStack align="center" textAlign="center" spacing={5} pt={7}>
        <EmptyStateIllustration />
        <Text variant="subtle">
          {showUnreadEmptyState ? 'There are no more unread conversations.' : 'No conversations found.'}
        </Text>
        {showUnreadEmptyState && (
          <Button variant="ghost" onClick={(): void => clearChannelFilter('unread-empty-state')}>
            View all conversations
          </Button>
        )}
      </VStack>
    );
  }, [showUnreadEmptyState, clearChannelFilter]);

  const containerWidth = useMemo(() => {
    if (isDesktop) return '388px';
    if (isTablet) return '300px';
    return '100%';
  }, [isDesktop, isTablet]);

  return (
    <Flex
      className="ConversationsList"
      flex="0 0 auto"
      direction="column"
      w={containerWidth}
      borderRight="1px"
      borderRightColor="border.default"
    >
      <ChannelListToolbar
        isSearching={isSearching}
        isLoading={false}
        currentChannelFilterSelection={currentChannelFilterSelection}
        searchResultsCount={searchResultsCount}
        showSearchResultsCount={showSearchResultsCount}
        isFilterApplied={isFilterApplied}
        onSearchToggle={handleSearchToggle}
        onChannelSearchInputChange={handleChannelSearchInputChange}
        onClearFilter={clearChannelFilter}
        onSelectFilter={handleFilterSelect}
        onChannelListViewSelect={handleChannelListViewSelect}
        onShowArchivedToggle={handleShowArchivedToggle}
      />

      <Flex
        className="ConversationsList__Container--Outer"
        px={2}
        pt={2}
        bgColor="background.default"
        overflowY="hidden"
        flex="1 0 0"
      >
        <VStack
          className="ConversationsList__Container--Inner"
          align="stretch"
          justify="stretch"
          ref={channelListContainerRef}
          bgColor="background.alpha"
          borderRadius="xl"
          px={3}
          pt={3}
          pb="1px"
          overflowY="auto"
          w="100%"
        >
          {!!(showUnreadEmptyState || showEmptyState)
            ? conversationsEmptyState
            : sortedChannels.map((channel) => (
                <ChannelListItem
                  key={channel.id}
                  channel={channel}
                  isCurrentChannel={!!channelId && channel.id === channelId}
                  isChannelPinned={channel.isPinned}
                  isLoadingNextPage={isLoadingNextPage}
                  hasUnreadMessages={
                    unreadChannelIds
                      ? unreadChannelIds.includes(channel.id) && unreadMessageCountByChannel[channel.id]?.count > 0
                      : false
                  }
                  onChannelSelect={handleChannelSelect}
                />
              ))}

          <VStack className="Conversations__LoadingContainer" h="100%" w="100%" align="center" justify="center">
            {hasNextPage && !showEmptyState ? (
              <Box className="Conversations__LoadingSpinner" p={5} ref={channelListEndRef}>
                <LoadingIndicator />
              </Box>
            ) : !!conversationsEmptyState ? null : (
              <Text className="Conversations__LoadingText" p={5} variant="subtle">
                You&apos;ve seen them all!
              </Text>
            )}
          </VStack>
        </VStack>
      </Flex>
    </Flex>
  );
};

export default ChannelList;
