import { AutomationButtonAction } from '@televet/shared-types/JsonTypes/WorkflowEventActionConfig';

export const automationActionLabels = {
  [AutomationButtonAction.AddConversationTags]: 'Add Conversation Tags',
  [AutomationButtonAction.PinConversation]: 'Pin Conversation',
  [AutomationButtonAction.ScheduleWorkflow]: 'Schedule Automation',
  [AutomationButtonAction.SetAppointmentStatus]: 'Set Appointment Status',
  [AutomationButtonAction.SetConversationStatus]: 'Set Conversation Status',
};

export const automationActionDescriptions = {
  [AutomationButtonAction.AddConversationTags]: undefined,
  [AutomationButtonAction.PinConversation]: undefined,
  [AutomationButtonAction.ScheduleWorkflow]:
    'Scheduled automations must be the same “Automation Type” as the one you are editing.',
  [AutomationButtonAction.SetAppointmentStatus]: undefined,
  [AutomationButtonAction.SetConversationStatus]: undefined,
};
