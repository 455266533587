import { Box, Collapse, Divider, Flex, useDisclosure } from '@televet/kibble-ui/build/chakra';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { MenuItemProps } from '@televet/kibble-ui/build/components/Menu/components/MenuItem';
import { Select } from '@televet/kibble-ui/build/components/Select';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { Text } from '@televet/kibble-ui/build/components/Text';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { ClinicCarePlanFragment } from 'shared/types/graphql';
import { useUpgradeCalculator } from '../../../hooks/useUpgradeCalculator';
import { formatCurrency } from '../../../utils/currency';
import { useManageMembershipContext } from '../context/ManageMembershipContext';
import { IFormValues } from '../types';

interface UpgradeInformationProps {
  enrollmentId: string;
  plans?: ClinicCarePlanFragment[];
  upgradePlanOptions: MenuItemProps[];
  subscriptionPaymentLast4: string;
  control: Control<IFormValues>;
  setValue: (name: string, value: string | number) => void;
}

const UpgradeInformation = ({
  enrollmentId,
  plans,
  upgradePlanOptions,
  subscriptionPaymentLast4,
  control,
  setValue,
}: UpgradeInformationProps): JSX.Element => {
  const { isOpen: isFeeOpen, onToggle: onFeeToggle } = useDisclosure();
  const { isAbleToUpgrade, setIsAbleToUpgrade } = useManageMembershipContext();

  const firstAvailablePlanOption = useMemo(
    () => upgradePlanOptions.find((planOption) => !planOption.isDisabled),
    [upgradePlanOptions],
  );

  const [selectedPlanId, setSelectedPlanId] = useState(firstAvailablePlanOption?.value || '');
  const { currentPlan, selectedPlan, differenceBetweenPlans, paymentsMade, upgradeFee, isLoading } =
    useUpgradeCalculator({
      enrollmentId,
      selectedPlanId,
      plans: plans || [],
    });

  const onUpgradePlanSelect = useCallback(
    (selectedItem: MenuItemProps): void => {
      const planId = selectedItem.value || '';
      setSelectedPlanId(planId);
      setValue('upgradePlanId', planId);
    },
    [setValue],
  );

  useEffect(() => {
    setIsAbleToUpgrade(selectedPlan.monthlyCost >= currentPlan.monthlyCost);
  }, [currentPlan.monthlyCost, selectedPlan.monthlyCost, setIsAbleToUpgrade]);

  const highestTierPlan = useMemo(
    () =>
      plans?.reduce((prev, current) => {
        return prev && prev.planPricings[0]?.pricePerRenewal > current.planPricings[0]?.pricePerRenewal ? prev : current;
      }),
    [plans],
  );

  return (
    <Box pt={4}>
      <Box>
        <Text as="p" size="md" fontWeight="bold" pb={2}>
          Upgrade to which plan?
        </Text>
        <Controller
          name="upgradePlanId"
          control={control}
          as={Select}
          options={upgradePlanOptions}
          listProps={{
            onSelect: (selectedItem: MenuItemProps): void => onUpgradePlanSelect(selectedItem),
          }}
          defaultValue={selectedPlanId}
          isDisabled={currentPlan.id === highestTierPlan?.id}
        />
        {isLoading ? (
          <Flex alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <>
            {isAbleToUpgrade ? (
              <Box pt={4}>
                <Box>
                  <Flex justifyContent="space-between">
                    <Text fontWeight="semibold">Upgrade Cost:</Text>
                    <Flex>
                      <Button
                        size="xs"
                        iconName={isFeeOpen ? 'chevronUp' : 'chevronDown'}
                        iconPosition="right"
                        variant="ghostNeutral"
                        onClick={onFeeToggle}
                        mr={1}
                      >
                        Show {isFeeOpen ? 'Less' : 'More'}
                      </Button>
                      <Text fontWeight="semibold">{formatCurrency(upgradeFee, true)}</Text>
                    </Flex>
                  </Flex>
                  <Collapse in={isFeeOpen}>
                    <Box px={1} mt={1}>
                      <Flex justifyContent="space-between">
                        <Text variant="subtle" size="sm">
                          {selectedPlan.title || 'New Plan'} Monthly Cost
                        </Text>
                        <Text variant="subtle" size="sm">
                          {formatCurrency(selectedPlan.monthlyCost, true)}
                        </Text>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <Text variant="subtle" size="sm">
                          {currentPlan.title || 'Old Plan'} Monthly Cost
                        </Text>
                        <Text variant="subtle" size="sm">
                          ({formatCurrency(currentPlan.monthlyCost, true)})
                        </Text>
                      </Flex>
                      <Divider my={1} />
                      <Flex justifyContent="space-between">
                        <Text size="sm" fontWeight="semibold">
                          Subtotal:
                        </Text>
                        <Text size="sm" fontWeight="semibold">
                          {formatCurrency(differenceBetweenPlans, true)}
                        </Text>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <Text variant="subtle" size="sm">
                          Payments Made on {currentPlan.title || 'Old Plan'}
                        </Text>
                        <Text variant="subtle" size="sm">
                          x{paymentsMade.quantity}
                        </Text>
                      </Flex>
                      <Divider my={1} />
                      <Flex justifyContent="space-between">
                        <Text size="sm" fontWeight="semibold">
                          Total Owed:
                        </Text>
                        <Text size="sm" fontWeight="semibold">
                          {formatCurrency(upgradeFee, true)}
                        </Text>
                      </Flex>
                    </Box>
                  </Collapse>
                </Box>
                <Flex mt={2}>
                  <Box>
                    <Icon name="infoCircle" size="sm" variant="subtle" />
                  </Box>
                  <Text variant="subtle" ml={1} size="sm">
                    Card on file {!!subscriptionPaymentLast4.length ? `ending in ` : ''}
                    <Text variant="subtle" fontWeight="semibold" size="sm">
                      {subscriptionPaymentLast4}
                    </Text>{' '}
                    will be charged for the above amount. Any benefits used from previous plan will be subtracted from
                    the balance of the new plan.
                  </Text>
                </Flex>
              </Box>
            ) : (
              <>
                <Alert
                  title={`${
                    currentPlan.id === highestTierPlan?.id
                      ? 'This client is currently on the highest tier plan and cannot be upgraded.'
                      : ''
                  } Downgrades are not supported.`}
                  status="info"
                  hideCloseButton
                  mt={4}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default UpgradeInformation;
